'use client';

import { usePathname } from 'next/navigation';
import { HTMLAttributes, useEffect, useRef } from 'react';

type Props = {} & HTMLAttributes<HTMLButtonElement>;

function Hamburger(props: Props) {
  const pathname = usePathname();
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    ref?.current?.removeAttribute('aria-expanded');
  }, [pathname]);

  return (
    <button
      className='hamburger'
      ref={ref}
      {...props}
      onClick={e => {
        if (e.currentTarget?.hasAttribute('aria-expanded')) {
          e.currentTarget?.removeAttribute('aria-expanded');
        } else {
          e.currentTarget?.setAttribute('aria-expanded', 'true');
        }
      }}
    >
      <span />
    </button>
  );
}

export default Hamburger;
