'use client';

import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import * as PopoverPrimitive from '@radix-ui/react-popover';
import RawHtml from '@components/RawHtml';
import clsx from 'clsx';

type Props = {
  content: ReactNode | string;
  buttonText: ReactNode;
  ariaButtonText?: string;
  className?: string;
  containerClassName?: string;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
};

function Popover(props: Props) {
  const { content, buttonText, className, containerClassName, open, setOpen, ariaButtonText } =
    props;

  return (
    <div className={clsx('popover', className, containerClassName)}>
      <PopoverPrimitive.Root open={open} onOpenChange={setOpen}>
        <PopoverPrimitive.Trigger
          className={clsx(className, 'popover__trigger')}
          aria-label={ariaButtonText}
        >
          {buttonText}
        </PopoverPrimitive.Trigger>
        <PopoverPrimitive.Content className={clsx(className, 'popover__content')} side='top'>
          {/* <PopoverPrimitive.Close className={clsx(className,'popover__close')}>&times;</PopoverPrimitive.Close> */}
          <PopoverPrimitive.Arrow className={clsx(className, 'popover__arrow')} />
          {typeof content === 'string' ? <RawHtml html={content} /> : content}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Root>
    </div>
  );
}

export default Popover;
